<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">Payment result</div>
        <div class="card-body">
          <app-loader *ngIf="!paymentResult"></app-loader>

          <div *ngIf="paymentResult">
            <div class="text-center">
              <img *ngIf="paymentResult.IsSuccessful" alt="Error" src="assets/images/success.svg" width="100"/>
              <img *ngIf="!paymentResult.IsSuccessful" alt="Error" src="assets/images/error-warning-line.svg" width="100"/>
            </div>

<!--            <h1 *ngIf="paymentResult.IsSuccessful" class="text-center">Your Code: {{paymentResult.Id}}</h1>-->
            <h5 *ngIf="paymentResult.IsSuccessful" class="card-title">Successful payment</h5>
            <h5 *ngIf="!paymentResult.IsSuccessful" class="card-title text-danger">Payment failed!</h5>
            <p>
              {{paymentResult.Message}}
            </p>

          </div>


        </div>
      </div>

    </div>
  </div>
</div>
