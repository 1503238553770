import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  gatewayPath = 'https://test.oppwa.com/';
  apiPath = 'http://localhost:3003/api/';
  clientPath = 'http://localhost:38052/';
}
