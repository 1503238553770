<div class="container">
  <div class="header" [ngStyle]="{ 'background-color': color }">
    <div class="d-flex">
      <div class="mx-auto mt-1" *ngIf="isVAMED">
        <a href="https://www.teamgesund.at/" target="_blank">
          <img src="../../../assets/images/logo_tgm_60h.png" alt="logo" class="img-logo" />
        </a>
      </div>
    </div>
    <!-- <i class="fa fa-angle-left head" [ngStyle]="{ color: color }"></i> -->
  </div>

  <div class="wrapper validation">
    <div class="col-12 col-md-6 validation-box">
      <div class="col-12" *ngIf="practiceInfos">
        <div class="title">
          Ihr Termin bei <br />
          {{ practiceInfos.owner ? practiceInfos.owner.PersonName : practiceInfos.practice.Name }}
        </div>
        <div *ngIf="selectedRoom">
          {{ selectedRoom.RoomName }}<br />
          {{ selectedRoom.LocationDescription }}
        </div>
        <div>
          am {{ selectedValues.Event.Start | dayjsDate : 'EEE d. LLLL' }} <br />
          um {{ selectedValues.Event.Start | dayjsDate : 'HH:mm' }} wurde erfolgreich gebucht
        </div>
      </div>
    </div>
  </div>
</div>
