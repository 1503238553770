<div class="container">
  <div class="header" [ngStyle]="{ 'background-color': color }">
    <div class="d-flex justify-content-between">
      <div>
        <i class="fa fa-angle-left head" [ngStyle]="{ color: color }"></i>
      </div>
      <div class="mt-1" *ngIf="isVAMED">
        <a href="https://www.teamgesund.at/" target="_blank">
          <img src="../../../assets/images/logo_tgm_60h.png" alt="logo" class="img-logo" />
        </a>
      </div>
      <div>
        <a class="circle not-selected last">4</a>
        <a class="circle not-selected">3</a>
        <a class="circle not-selected">2</a>
        <a class="circle selected mouseover" [ngStyle]="{ color: color }">1</a>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="row physician">
      <div class="col-12 col-picture">

        <div *ngIf="practiceInfos">
          <ng-container *ngIf="!practiceInfos.owner">
            <img
              class="img-fluid img-circle"
              *ngIf="physicianPicture?.changingThisBreaksApplicationSecurity.length > 26"
              [src]="physicianPicture"
              alt="portrait"
            />
            <img
              class="img-fluid img-circle"
              *ngIf="!(physicianPicture?.changingThisBreaksApplicationSecurity.length > 26)"
              src="../../../assets/images/Placeholder.png"
              alt="portrait"
            />
          </ng-container>
          <ng-container *ngIf="practiceInfos.owner">
            <img
              class="img-fluid img-circle"
              *ngIf="personPicture?.changingThisBreaksApplicationSecurity.length > 26"
              [src]="personPicture"
              alt="portrait"
            />
            <img
              class="img-fluid img-circle"
              *ngIf="!(personPicture?.changingThisBreaksApplicationSecurity.length > 26)"
              src="../../../assets/images/Placeholder.png"
              alt="portrait"
            />
          </ng-container>
        </div>

        <div class="wrapper-box" *ngIf="practiceInfos">
          <ng-container *ngIf="!practiceInfos.owner && !practiceInfos.eventTypeGroup">
            <div class="title">
              {{ practiceInfos.practice.Name }}
            </div>
            <div class="text">
              {{ practiceInfos.practice.Name2 }}
            </div>
            <div class="small">
              {{ practiceInfos.practice.PatientNotes }}
            </div>
          </ng-container>
          <ng-container *ngIf="practiceInfos.owner">
            <div class="title">
              {{ practiceInfos.owner.PersonName }}
            </div>
            <div class="small">
              {{ practiceInfos.owner.PersonDescription }}
            </div>
          </ng-container>
          <ng-container *ngIf="practiceInfos.eventTypeGroup">
            <div class="title">
              {{ practiceInfos.eventTypeGroup.GroupName }}
            </div>
            <div class="small">
              {{ practiceInfos.eventTypeGroup.GroupDescription }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper" *ngIf="rooms?.length">
    <div class="btn-group btn-group-toggle row" ngbRadioGroup name="locationRadios" [(ngModel)]="selectedRoomID">
      <label ngbButtonLabel class="btn-radio col-12 col-md-6 col-services" *ngFor="let room of rooms; index as i">
        <input ngbButton type="radio" [value]="room.Care01RoomID" />
        <!-- [ngStyle]="{ background: color, 'border-color': color }" -->
        <div class="box box-circle">
          <i class="far fa-hospital calender"></i>
        </div>
        <div class="wrapper-box">
          <div class="title">
            {{ room.RoomName }}
          </div>
          <div class="small">
            {{ room.LocationDescription }}
          </div>
        </div>
      </label>
    </div>
  </div>
  <div class="wrapper">
    <div
      class="row services"
      *ngFor="let service of filteredServices(selectedRoomID); index as i"
      (click)="onSelect(service.Care01EventTypeID, service.EventName, selectedRoomID)"
    >
      <div class="col-12 col-services">
        <div class="box box-circle" [ngStyle]="{ background: color, 'border-color': color }">
          <i class="far fa-calendar-alt calender"></i>
        </div>
        <div class="wrapper-box">
          <div class="title">
            {{ service.EventName }}
          </div>
          <div class="text">
            {{ service.EventDetails }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
