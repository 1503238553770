<div class="container">
  <div class="header" [ngStyle]="{ 'background-color': color }">
    <div class="d-flex justify-content-between">
      <div>
        <a><i class="fa fa-angle-left head mouseover" (click)="goTo(2)"></i></a>
      </div>
      <div class="mt-1" *ngIf="isVAMED">
        <a href="https://www.teamgesund.at/" target="_blank">
          <img src="../../../assets/images/logo_tgm_60h.png" alt="logo" class="img-logo" />
        </a>
      </div>
      <div>
        <a class="circle not-selected last">4</a>
        <a class="circle selected mouseover" [ngStyle]="{ color: color }">3</a>
        <a class="circle selected mouseover" (click)="goTo(2)" [ngStyle]="{ color: color }">2</a>
        <a class="circle selected mouseover" (click)="goTo(1)" [ngStyle]="{ color: color }">1</a>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="row">
      <div class="col-sm-8 col-12 margin-auto" *ngIf="practiceInfos">
        <ng-container *ngIf="!practiceInfos.owner">
          <img
            class="img-fluid img-circle"
            *ngIf="physicianPicture?.changingThisBreaksApplicationSecurity.length > 26"
            [src]="physicianPicture"
            alt="portrait"
          />
          <img
            class="img-fluid img-circle"
            *ngIf="!(physicianPicture?.changingThisBreaksApplicationSecurity.length > 26)"
            src="../../../assets/images/Placeholder.png"
            alt="portrait"
          />
        </ng-container>
        <ng-container *ngIf="practiceInfos.owner">
          <img
            class="img-fluid img-circle"
            *ngIf="personPicture?.changingThisBreaksApplicationSecurity.length > 26"
            [src]="personPicture"
            alt="portrait"
          />
          <img
            class="img-fluid img-circle"
            *ngIf="!(personPicture?.changingThisBreaksApplicationSecurity.length > 26)"
            src="../../../assets/images/Placeholder.png"
            alt="portrait"
          />
        </ng-container>
        <div class="wrapper-box">
          <div class="title" *ngIf="!practiceInfos.eventTypeGroup; else eventOnly">
            {{ practiceInfos.event }} bei
            {{ practiceInfos.owner ? practiceInfos.owner.PersonName : practiceInfos.practice.Name }}
          </div>
          <ng-template #eventOnly>
            <div class="title">
              {{ practiceInfos.event }}
            </div>
          </ng-template>
          <div class="text">
            <div *ngIf="selectedRoom">{{ selectedRoom.RoomName }}</div>
            <div>
              Am {{ selectedValues.Event.Start | dayjsDate : 'EEE d. LLLL' }} um
              {{ selectedValues.Event.Start | dayjsDate : 'HH:mm' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Reactive Form -->

    <div class="row">
      <div class="col-lg-1 col-md-1 service"></div>
      <div class="col-lg-10 col-md-10 service">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
          <div class="form-row">
            <div class="col col-12 col-md-3">
              <label for="gender">Anrede{{ practiceInfos?.practice?.EnableGenderValidation ? '*' : '' }}</label>
              <select class="form-control test" formControlName="salutation">
                <option value="1">Herr</option>
                <option value="2">Frau</option>
              </select>
            </div>

            <div class="col col-12 col-md-4">
              <label for="firstName">Vorname*</label>
              <input
                class="form-control test"
                type="text"
                placeholder="Vorname (PatientIn)"
                formControlName="firstName"
              />
            </div>

            <div class="col col-12 col-md-5">
              <label for="lastName">Nachname*</label>
              <input
                class="form-control test"
                type="text"
                placeholder="Nachname (PatientIn)"
                formControlName="lastName"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4 mb-3">
              <label for="title">Titel</label>
              <input class="form-control test" type="text" placeholder="Titel" formControlName="title" />
            </div>

            <div class="col-md-4 mb-3" formGroupName="SvCheck">
              <label for="dateOfBirth">Geburtsdatum*</label>
              <input
                class="form-control test"
                placeholder="dd.mm.yyyy"
                (click)="d.toggle()"
                [minDate]="minDate"
                [maxDate]="maxDate"
                ngbDatepicker
                #d="ngbDatepicker"
                formControlName="dateOfBirth"
              />
            </div>

            <div class="col-md-4 mb-3" formGroupName="SvCheck">
              <label for="insuranceNo">Vers. Nr. (Ohne Datum)</label>
              <input
                class="form-control test"
                type="text"
                maxlength="4"
                placeholder="Versicherungs Nr."
                formControlName="socialSecurity"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="eMail">E-Mail*</label>
              <input class="form-control test" type="email" placeholder="E-Mail Adresse" formControlName="email" />
            </div>

            <div class="col-lg-6 mb-3">
              <label for="validationTooltip01">Mobiltelefonnummer (f&uuml;r die Bestätigung)*</label>
              <input class="form-control test" type="text" placeholder="06601234567" formControlName="mobile" />
            </div>
          </div>

          <div class="form-row" *ngIf="practiceInfos?.practice?.EnableTaskNotesFormField">
            <div class="col-md-12 mb-3">
              <label for="taskNotes">Anmerkungen</label>
              <textarea
                class="form-control"
                placeholder="Anmerkungen / Fragen zum Termin (max. 500 Zeichen)"
                formControlName="taskNotes"
                maxlength="500"
              ></textarea>
            </div>
          </div>

          <div class="form-row">
            <ng-container *ngIf="!!isVAMED; else defaultCheckboxes">
              <div class="col-md-12 mb-3">
                <input type="checkbox" class="form-check-input" formControlName="vamedCheck1" name="vamedCheck1" />
                <label class="form-check-label" for="vamedCheck1"
                  >Hiermit nehme ich zur Kenntnis, dass es sich bei TEAM GESUND um ein privates Medizin Zentrum handelt
                  und die Behandlung kostenpflichtig ist. Diese Kosten sind von der Patientin / dem Patienten direkt vor
                  Ort zu bezahlen. Ausgenommen ist die Verrechnung der Videokonsultation - diese erfolgt
                  separat.*</label
                >
              </div>

              <div class="col-md-12 mb-3">
                <input type="checkbox" class="form-check-input" formControlName="vamedCheck2" name="vamedCheck2" />
                <label class="form-check-label" for="vamedCheck2"
                  >Ich habe zur Kenntnis genommen, dass Termine, die nicht innerhalb von 24 Stunden (ausgenommen aus
                  Krankheitsgründen) abgesagt oder verschoben werden, von TEAM GESUND zur Gänze in Rechnung gestellt
                  werden. Wir bitten Sie, jegliche Absagen oder Terminverschiebungen zeitgerecht entweder telefonisch
                  unter 01/361681030 oder via Email unter
                  <a href="mailto:office@teamgesund.at">office@teamgesund.at</a> bekanntzugeben.*</label
                >
              </div>

              <div class="col-md-12 mb-3">
                <input type="checkbox" class="form-check-input" formControlName="vamedCheck3" name="vamedCheck3" />
                <label class="form-check-label" for="vamedCheck3"
                  >Ich habe die
                  <a href="https://www.teamgesund.at/datenschutz/" target="_blank" rel="noreferrer noopener"
                    >Datenschutzbestimmungen</a
                  >
                  gelesen und akzeptiert.*</label
                >
              </div>
            </ng-container>
            <ng-template #defaultCheckboxes>
              <div class="col-md-12 mb-3">
                <input type="checkbox" class="form-check-input" formControlName="check" />
                <label class="form-check-label" for="exampleCheck1"
                  >Ich bin mit den
                  <a routerLink="/agbs" routerLinkActive="active" target="_blank">Nutzungsbedingungen</a> und der
                  <a routerLink="/datenschutz" routerLinkActive="active" target="_blank">Datenschutzerklärung</a> der
                  Peacequare GmbH einverstanden*</label
                >
              </div>

              <div class="col-md-12 mb-3" *ngIf="this.profileForm.get('portalTermsCheck').enabled">
                <input type="checkbox" class="form-check-input" formControlName="portalTermsCheck" />
                <label class="form-check-label" for="exampleCheck1"
                  >Ich bin mit den
                  <a [href]="practiceInfos?.practice?.CustomTermsURL" target="_blank" rel="noopener noreferrer"
                    >Nutzungsbedingungen</a
                  >
                  von {{ practiceInfos?.practice?.Name }} einverstanden*</label
                >
              </div>
            </ng-template>
          </div>

          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!profileForm.valid"
            *ngIf="!ongoingSubmit"
            [ngStyle]="{ 'background-color': color }"
          >
            Abschicken
          </button>
          <button
            class="btn btn-primary"
            type="submit"
            disabled
            *ngIf="!!ongoingSubmit"
            [ngStyle]="{ 'background-color': color }"
          >
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>

          <div class="col-md-12 font-italic mt-3">
            <label
              >Bitte beachten Sie, dass Sie hier die Stammdaten des Patienten, möglicherweise Ihres Kindes angeben und
              nicht Ihre persönlichen als Hauptversicherter.</label
            >
          </div>

          <div class="col-md-12 font-italic" *ngIf="isVAMED">
            <label>* Pflichtfelder</label>
          </div>

          <div clas="col-md-12 mb-3">
            <p
              class="err-msg"
              *ngIf="!profileForm.controls['salutation'].valid && !profileForm.controls['salutation'].pristine"
            >
              Bitte wählen Sie eine Anrede aus.
            </p>
            <p
              class="err-msg"
              *ngIf="!profileForm.controls['firstName'].valid && !profileForm.controls['firstName'].pristine"
            >
              Bitte geben Sie einen gültigen Vornamen an.
            </p>
            <p
              class="err-msg"
              *ngIf="!profileForm.controls['lastName'].valid && !profileForm.controls['lastName'].pristine"
            >
              Bitte geben Sie einen gültigen Nachnamen an.
            </p>
            <p class="err-msg" *ngIf="!profileForm.controls['email'].valid && !profileForm.controls['email'].pristine">
              Bitte geben Sie einen gültige E-Mail Adresse an.
            </p>
            <p
              class="err-msg"
              *ngIf="(!profileForm.controls['mobile'].valid && !profileForm.controls['mobile'].pristine) || mobileErr"
            >
              Bitte geben Sie einen gültige Mobiltelefonnummer (Format z.B 06641234567) an.
            </p>
            <p
              class="err-msg"
              *ngIf="
                !profileForm.controls['SvCheck'].valid && !profileForm.controls.SvCheck.get('dateOfBirth').pristine
              "
            >
              Bitte kontrollieren Sie die Versicherungsnummer und das Geburtsdatum
            </p>
            <p class="err-msg" *ngIf="!!appointmentTakenErr">
              Dieser Termin ist leider bereits vergeben. Bitte gehen Sie einen Schritt zurück und wählen Sie einen
              anderen.
            </p>
          </div>
        </form>
      </div>
      <div class="col-lg-1 col-md-1 service"></div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Modal title</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>One fine body&hellip;</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
