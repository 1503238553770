<div id="spinner" *ngIf="!colorIsLoaded; else loaded">
  <i class="fas fa-spinner fa-spin"></i>
</div>

<ng-template #loaded>
  <ng-container *ngIf="errorMessage; else bookingSteps">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>
  </ng-container>
  <ng-template #bookingSteps>
    <app-step1
      *ngIf="colorIsLoaded"
      [selectedValues]="selectedValues"
      [services]="services"
      [rooms]="currentRooms$ | async"
      [selectedRoomID]="selectedRoomID"
      [practiceInfos]="practiceInfos"
      [color]="color"
      (values)="setValues($event)"
      (infos)="setInfos($event)"
      [hidden]="step != 1"
      (nextStep)="onClick($event)"
      [isVAMED]="isVAMED"
      [physicianPicture]="physicianPicture"
      [personPicture]="personPicture"
    >
    </app-step1>

    <app-step2
      [selectedValues]="selectedValues"
      [dates]="dates"
      [filteredDates]="filteredDates"
      [practiceInfos]="practiceInfos"
      [selectedRoom]="getRoom(selectedValues.RoomID)"
      [showNext]="lastSearch?.getTime() <= limit?.getTime()"
      [color]="color"
      (values)="setValues($event)"
      (infos)="setInfos($event)"
      (fetch)="fetchDates($event)"
      (clearDates)="clearDates($event)"
      [hidden]="step != 2"
      (nextStep)="onClick($event)"
      [isVAMED]="isVAMED"
    >
    </app-step2>

    <app-step3
      [selectedValues]="selectedValues"
      [practiceInfos]="practiceInfos"
      [selectedRoom]="getRoom(selectedValues.RoomID)"
      [mobileErr]="mobileErr"
      [appointmentTakenErr]="appointmentTakenErr"
      [ongoingSubmit]="submitPending"
      [color]="color"
      (submit)="onSubmit($event)"
      [hidden]="step != 3"
      (nextStep)="onClick($event)"
      [isVAMED]="isVAMED"
      [physicianPicture]="physicianPicture"
      [personPicture]="personPicture"
    >
    </app-step3>

    <app-step4
      [selectedValues]="selectedValues"
      [verification]="verification"
      [hidden]="step != 4"
      [color]="color"
      (nextStep)="onClick($event)"
      [isVAMED]="isVAMED"
      [physicianPicture]="physicianPicture"
      [personPicture]="personPicture"
    >
    </app-step4>

    <app-step5
      [selectedValues]="selectedValues"
      [practiceInfos]="practiceInfos"
      [selectedRoom]="getRoom(selectedValues.RoomID)"
      [hidden]="step != 5"
      [color]="color"
      (nextStep)="onClick($event)"
      [isVAMED]="isVAMED"
      [physicianPicture]="physicianPicture"
      [personPicture]="personPicture"
    >
    </app-step5>
  </ng-template>
</ng-template>
