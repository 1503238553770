import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SelectedValues } from '../SelectedValues';
import { Physician } from '../Physician';
import { PracticeInfo } from '../PracticeInfo';
import { RoomsService } from '../services/rooms.service';
import { IPracticeDataResponse } from '../services/dates.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css'],
})
export class Step1Component implements OnInit {
  physician;
  mockPhysicians: Physician[] = [
    { name: 'Arzt 1', field: 'Facharzt für dies' },
    { name: 'Arzt 2', field: 'Facharzt für das' },
    { name: 'Arzt 3', field: 'Facharzt für dies' },
    { name: 'Arzt 4', field: 'Facharzt für das' },
    { name: 'Arzt 5', field: 'Facharzt für dies' },
  ];

  @Input() selectedValues: SelectedValues;
  @Input() services;
  @Input() rooms;
  @Input() practiceInfos: any;
  @Input() selectedRoomID;
  @Input() color;
  @Input() isVAMED?: boolean;
  @Input() physicianPicture?: string;
  @Input() personPicture?: string;

  @Output() values = new EventEmitter<SelectedValues>();
  @Output() infos = new EventEmitter<IPracticeDataResponse>();
  @Output() nextStep = new EventEmitter<number>();

  constructor(private roomsService: RoomsService) {}

  ngOnInit() {}

  onSelect(eventTypeId: string, event: string, roomID: number): void {
    this.selectedValues.EventTypeID = eventTypeId;
    this.selectedValues.RoomID = roomID;
    this.practiceInfos.event = event;
    this.values.emit(this.selectedValues);
    this.infos.emit(this.practiceInfos);
    this.nextStep.emit(2);
  }

  filteredServices(roomID) {
    if (!roomID) {
      return this.services;
    }
    return this.services.filter(
      (item) =>
        !item.RoomIDs ||
        item.RoomIDs.split(',')
          .map((roomIDItem) => parseInt(roomIDItem, 10))
          .indexOf(roomID) > -1
    );
  }
}
