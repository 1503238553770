import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { AgbsComponent } from './book-appointment/agbs/agbs.component';
import { DatenschutzComponent } from './book-appointment/datenschutz/datenschutz.component';
import { VideoCallViewComponent } from './feature/video-call/containers/video-call-view/video-call-view.component';
import { PaymentCardInfoComponent } from './payment/payment-card-info/payment-card-info.component';
import { PaymentResultComponent } from './payment/payment-result/payment-result.component';

const routes: Routes = [
  {
    path: 'payment',
    redirectTo: 'payment/card-info',
    pathMatch: 'full',
  },
  {
    path: 'payment/card-info',
    component: PaymentCardInfoComponent,
  },
  {
    path: 'payment/result',
    component: PaymentResultComponent,
  },
  {
    path: 'bookAppointment/:id', // nJ0O1ExJp9FFp8nfBhN7
    component: BookAppointmentComponent,
  },
  {
    path: 'videoCall/:id', // nJ0O1ExJp9FFp8nfBhN7
    component: VideoCallViewComponent,
  },

  {
    path: 'agbs',
    component: AgbsComponent,
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
  },
  {
    path: '**',
    redirectTo: '',
    // pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
