import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface CCRoom {
  id: number;
  Care01RoomID: number;
  PortalID: number;
  RoomName: string;
  RoomDescription: string;
  RoomCategoryID: number;
  StatusID: number;
  CreatedOn: Date;
  CreatedBy: string;
  EditedOn: Date;
  EditedBy: string;
  LocationDescription: string;
  Active: boolean;
}

@Injectable()
export class RoomsService {
  private items$$ = new BehaviorSubject<any>([]);
  public items$ = this.items$$.asObservable();
  constructor() {}

  get() {
    return this.items$$.getValue();
  }

  set(newItems: any[]) {
    this.items$$.next(newItems);
  }

  filter(filterFn: any): any[] {
    return this.items$$.getValue().filter(filterFn);
  }

  find(findFn: any): any {
    return this.items$$.getValue().find(findFn);
  }
}
