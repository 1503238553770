import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface IPCAuthResult {
  token: string;
  pcConfig: any;
}

@Injectable()
export class VideoCallClientService {
  constructor(private http: HttpClient) {}

  authenticate(room: string, pw: string): Observable<IPCAuthResult> {
    return this.http.post<IPCAuthResult>(`${environment.patientConnectUrl}/auth`, { room, pw });
  }
}
