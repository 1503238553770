import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkifyjs/string';

/**
 * transforms the urls in a string into clickable links
 */
@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return value ? linkifyStr(value) : value;
  }
}
