import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * A moment timezone pipe to support parsing based on time zone abbreviations
 * covers all cases of offset variation due to daylight saving.
 *
 * Same API as DatePipe with additional timezone abbreviation support
 * Official date pipe dropped support for abbreviations names from Angular V5
 */
@Pipe({
  name: 'dayjsDate',
})
export class DayJSDatePipe extends DatePipe implements PipeTransform {
  transform(value: string | Date, format: string = 'mediumDate', timezone: string = 'Europe/Vienna'): string {
    const timezoneOffset = dayjs(value).tz(timezone).format('Z');
    return super.transform(value, format, timezoneOffset);
  }
}
