import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { VideoCallService } from '../../services/video-call.service';
import { take, filter, tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject, interval, defer } from 'rxjs';
import { IPCAuthResult } from '../../clients/video-call-client.service';
import { VideoCallSocketService } from '../../services/video-call-socket.service';

class InitStreamsError extends Error {
  type = 'INIT_ERROR';

  constructor(message) {
    super(message);
  }
}

const INIT_ACCESS_ERR_MSG =
  'Bitte überprüfen Sie die Verfügbarkeit Ihrer Webcam / Ihres Mikrofons und stellen Sie sicher, dass dieser Browser darauf Zugriff hat.\
   \r\nVergewissern Sie sich bitte auch, dass Ihre Webcam und Ihr Mikrofon aktuell von keinem anderen Programm verwendet werden.';

@Component({
  selector: 'app-video-call-view',
  templateUrl: './video-call-view.component.html',
  styleUrls: ['./video-call-view.component.css'],
})
export class VideoCallViewComponent implements OnInit, AfterViewInit {
  @ViewChild('video') videoEl: ElementRef;
  @ViewChild('remoteVideo') remoteVideoEl: ElementRef;
  @ViewChild('drawer') drawerEl: MatDrawer;

  private sessionId: string;
  private sessionPw: string;
  private sessionToken: string;
  private pcConfig: RTCConfiguration;

  localStream$: Observable<MediaStream>;
  remoteStream$: Observable<MediaStream>;
  micEnabled = true;

  hasPeerConnection$$ = new BehaviorSubject<boolean>(false);
  timer$: Observable<{ minutes: number; seconds: number }>;

  authRequest$: Observable<IPCAuthResult>;
  errorObject: Error;

  chatMessages$: Observable<any[]>;

  constructor(
    private route: ActivatedRoute,
    private videoCallService: VideoCallService,
    private socketService: VideoCallSocketService
  ) {}

  ngOnInit() {
    this.sessionId = this.route.snapshot.paramMap.get('id');
    this.sessionPw = this.route.snapshot.queryParamMap.get('pw');
    this.chatMessages$ = this.socketService.chatMessages$;
    this.localStream$ = this.socketService.localStream$;
    this.remoteStream$ = this.socketService.remoteStream$.pipe(
      tap((stream) => this.hasPeerConnection$$.next(!!stream)),
      tap(
        (stream) =>
          (this.timer$ = !!stream
            ? interval(1000).pipe(map((value) => ({ minutes: Math.floor(value / 60), seconds: value % 60 })))
            : null)
      )
    );
    this.authRequest$ = this.videoCallService.callAuthenticate(this.sessionId, this.sessionPw).pipe(
      tap((result) => {
        this.sessionToken = result.token;
        this.pcConfig = result.pcConfig;
      }),
      exhaustMap((result) => defer(() => this.initializeCall()).pipe(map(() => result))),
      catchError((err) => {
        this.errorObject = err;
        // return of(null);
        return throwError(err);
      })
    );
  }

  ngAfterViewInit() {}

  toggleMic(isEnabled: boolean): void {
    // this.localStream.getAudioTracks().forEach(track => (track.enabled = !isEnabled));
    this.socketService.setMicEnabled(isEnabled);
    // this.micEnabled = !isEnabled;
  }

  toggleVideo(isEnabled: boolean): void {
    // this.localStream.getAudioTracks().forEach(track => (track.enabled = !isEnabled));
    this.socketService.setVideoEnabled(isEnabled);
    // this.micEnabled = !isEnabled;
  }

  sendChatMessage(message: string): void {
    // this.chatMessages.push({ message, sender: '_self' });
    // this.socket.emit('message', { type: 'chat', message });
    this.socketService.sendChatMessage(message);
  }

  closeConnection(): void {
    this.socketService.closeConnection();
  }

  initializeCall(): Promise<any> {
    if (!this.sessionToken || !this.pcConfig) {
      return;
    }
    return this.videoCallService
      .getLocalMediaStream()
      .then((localStream) => this.socketService.initSocket(this.sessionToken, this.pcConfig, localStream))
      .catch((err) => {
        throw new InitStreamsError(INIT_ACCESS_ERR_MSG);
      });
    // .catch((err) => alert('getUserMedia() error: ' + err.name));
  }
}
