<div class="d-flex rounded h-100 flex-column">
  <div class="chat-box mt-2 flex-grow-1 align-start">
    <div class="m-2 text-break" *ngFor="let messageItem of chatMessages">
      <div
        class="w-75 p-2 rounded ml-auto text-white sent-message"
        *ngIf="messageItem.sender === '_self'; else receivedMessage"
        [innerHTML]="messageItem.message | linkify"
        >
      </div>
      <ng-template #receivedMessage>
        <div class="w-75 p-2 rounded mr-auto bg-light" [innerHTML]="messageItem.message | linkify">          
        </div>
      </ng-template>
    </div>
  </div>
  <div class="align-bottom">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        type="text"
        [(ngModel)]="currentMessage"
        (keyup)="$event.keyCode == 13 && onSendClick(currentMessage)"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button" (click)="onSendClick(currentMessage)">
          <i class="fas fa-paper-plane"></i>
        </button>
      </div>
    </div>
  </div>
</div>
