export class Event {
  Start: string;
  End: string;
  FirstName: string;
  LastNameEn: string;
  EMail: string;
  PhoneMobile: string;
  DateOfBirth: string;
  GenderID: string;
  InsuranceNo: string;
  PersonTitle: string;
  RoomID?: number;
  OwnerID?: number;
  Description?: string;

  constructor() {
    this.Start = '';
    this.End = '';
    this.FirstName = '';
    this.LastNameEn = '';
    this.EMail = '';
    this.PhoneMobile = '';
    this.DateOfBirth = '';
    this.GenderID = '';
    this.InsuranceNo = '';
    this.PersonTitle = '';
    this.RoomID = null;
    this.OwnerID = null;
    this.Description = '';
  }
}
