<div class="container">
  <div class="header">
    <i class="fa fa-angle-left head" style="color:#2bbfd9"></i>
  </div>

  <div class="wrapper">
    <div class="row extra">

      <div class="col-12">
        <p class="title">
          Datenschutzerklärung der Online-Terminreservierung
        </p>
        <p class="text">
          Die Online-Terminreservierung (in weiterer Folge auch OTR genannt) wird vom Gesundheitsdienstleister, bei dem
          Sie einen Termin vereinbaren, betrieben. Diese Datenschutzerklärung beschreibt wie der
          Gesundheitsdienstleister, als datenschutzrechtlich Verantwortlicher, Ihre personenbezogenen Daten im
          Zusammenhang mit dieser Terminreservierung verarbeitet. Allenfalls weitere Verarbeitungen Ihrer Daten können
          über Webseite des Gesundheitsdienstleisters erfolgen und dort geregelt sein.
        </p>
        <p class="title2">
          1. Welche Daten der Gesundheitsdienstleister über Sie verarbeitet
        </p>
        <p class="text">
          Im Zuge Ihrer Nutzung der Terminreservierung werden folgende Daten erhoben: Anrede, Titel, Vorname, Nachname,
          Geburtsdatum, E-Mail-Adresse, Mobiltelefonnummer, ausgewählter Gesundheitsdienstleister, allenfalls
          ausgewählter Mitarbeiter/Leistungserbringer, ausgewählte Leistungen, ausgewählte Buchungszeiten, Datum und
          Uhrzeit der Buchung.
        </p>
        <p class="text">
          Alle personenbezogenen Daten werden verschlüsselt gespeichert.
        </p>
        <p class="text">
          Die Angabe der Daten erfolgt freiwillig; sie ist jedoch Voraussetzung der Nutzung der Terminreservierung.
        </p>
        <p class="title2">
          2. Zwecke der Datenverarbeitung
        </p>
        <p class="text">
          Ihre personenbezogenen Daten werden zu folgenden Zwecken verarbeitet:

          Die Daten werden vom Gesundheitsdienstleister für den Zweck der Terminvereinbarung erhoben und verarbeitet.
          Weiters werden die Daten im Rahmen, der nicht meldepflichtigen Standardanwendung der Patientenverwaltung und
          Honorarabrechnung der Gesundheitsdienstleister verarbeitet. Die Speicherung und Aufbewahrung der Daten durch
          den Gesundheitsdienstleister erfolgt nach Maßgabe der einschlägigen gesetzlichen Regelungen. Die Peacequare
          GmbH & Co KG verarbeitet die bezeichneten personenbezogenen Daten als Auftragsverarbeiter für den
          Gesundheitsdienstleister zur Erbringung der in diesen Nutzungsbedingungen beschriebenen Leistungen zur
          Bereitstellung der Terminreservierung.
        </p>
        <p class="title2">
          3. Rechtsgrundlagen der Verarbeitung
        </p>
        <p class="text">
          Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen Daten ist die Erfüllung eines Vertrages
          bzw. von vorvertraglichen Maßnahmen (gemäß Art 6 Abs 1 lit b EU Datenschutz-Grundverordnung), die Erfüllung
          rechtlicher Verpflichtungen (gemäß Art 6 Abs 1 lit c EU Datenschutz-Grundverordnung) sowie das überwiegende
          berechtigte Interesse (gemäß Art 6 Abs 1 lit f EU Datenschutz-Grundverordnung) des Gesundheitsdienstleisters,
          welches darin besteht, die oben unter Punkt 3 genannten Zwecke zu erreichen.
        </p>
        <p class="title2">
          4. Übermittlung Ihrer personenbezogenen Daten
        </p>
        <p class="text">
          Zu den oben genannten Zwecken werden Ihre personenbezogenen Daten an Peacequare GmbH in der Rolle des
          Auftragsverarbeiters übermittelt.
        </p>
        <p class="title2">
          5. Dauer der Speicherung
        </p>
        <p class="text">
          Der Gesundheitsdienstleister wird Ihre Daten grundsätzlich für eine Dauer von 30 Jahren speichern. Eine
          längere Speicherung erfolgt, soweit dies aufgrund rechtlicher Vorgaben aus dem Arzt-Patient-Verhältnis
          erforderlich ist.
        </p>
        <p class="title2">
          6. Ihre Rechte im Zusammenhang mit personenbezogenen Daten
        </p>
        <p class="text">
          Sie sind unter anderem berechtigt (unter den Voraussetzungen anwendbaren Rechts), (i) zu überprüfen, ob und
          welche personenbezogenen Daten der Gesundheitsdienstleister über Sie gespeichert hat und Kopien dieser Daten
          zu erhalten, (ii) die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind
          oder nicht rechtskonform verarbeitet werden, zu verlangen, (iii) vom Gesundheitsdienstleister zu verlangen,
          die Verarbeitung Ihrer personenbezogenen Daten einzuschränken, und (iv) unter bestimmten Umständen der
          Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, (v) Datenübertragbarkeit zu verlangen, (vi) die
          Identität von Dritten, an welche Ihre personenbezogenen Daten übermittelt werden, zu kennen und (vii) bei der
          zuständigen Behörde (Datenschutzbehörde, www.dsb.gv.at) Beschwerde zu erheben.
        </p>
        <p class="title2">
          7. Kontaktdaten des Verantwortlichen
        </p>
        <p class="text">
          Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten Fragen oder Anliegen haben, wenden Sie sich
          bitte an den Gesundheitsdienstleister, bei dem Sie den Termin über die Terminreservierung vereinbart haben.
        </p>
      </div>
    </div>
  </div>
</div>
