import { Injectable } from '@angular/core';
import { VideoCallClientService, IPCAuthResult } from '../clients/video-call-client.service';
import { Observable } from 'rxjs';

@Injectable()
export class VideoCallService {
  constructor(private videoCallClient: VideoCallClientService) {}

  callAuthenticate(room: string, pw: string): Observable<IPCAuthResult> {
    return this.videoCallClient.authenticate(room, pw);
  }

  getLocalMediaStream() {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: { min: 320, max: 1280 },
        height: { min: 240, max: 720 },
        facingMode: 'user',
        frameRate: 30
      }
    });
  }
}
