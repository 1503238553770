import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-video-call-menu',
  templateUrl: './video-call-menu.component.html',
  styleUrls: ['./video-call-menu.component.css'],
})
export class VideoCallMenuComponent implements OnInit {
  @Input()
  hasPeerConnection: boolean;

  @Input()
  drawerEl: MatDrawer;

  @Input()
  timer: { minutes: number; seconds: number };

  @Output()
  toggleMic = new EventEmitter<boolean>();

  @Output()
  toggleVideo = new EventEmitter<boolean>();

  @Output()
  closePeerConnection = new EventEmitter<void>();

  @Output()
  openConnection = new EventEmitter<void>();

  micEnabled = true;
  videoEnabled = true;

  constructor() {}

  ngOnInit(): void {}
  onCloseConnection() {
    this.closePeerConnection.emit();
  }

  onOpenConnection() {
    this.openConnection.emit();
  }

  onToggleMic(isEnabled: boolean) {
    this.micEnabled = !isEnabled;
    this.toggleMic.emit(!isEnabled);
  }

  onToggleVideo(isEnabled: boolean) {
    this.videoEnabled = !isEnabled;
    this.toggleVideo.emit(!isEnabled);
  }
}
