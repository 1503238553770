<div class="container">
  <div class="header" [ngStyle]="{ 'background-color': color }">
    <div class="d-flex justify-content-between">
      <div>
        <a><i class="fa fa-angle-left head mouseover" (click)="goTo(3)"></i></a>
      </div>
      <div class="mt-1" *ngIf="isVAMED">
        <a href="https://www.teamgesund.at/" target="_blank">
          <img src="../../../assets/images/logo_tgm_60h.png" alt="logo" class="img-logo" />
        </a>
      </div>
      <div>
        <a class="circle selected mouseover last" [ngStyle]="{ color: color }">4</a>
        <a class="circle selected mouseover" (click)="goTo(3)" [ngStyle]="{ color: color }">3</a>
        <a class="circle selected mouseover" (click)="goTo(2)" [ngStyle]="{ color: color }">2</a>
        <a class="circle selected mouseover" (click)="goTo(1)" [ngStyle]="{ color: color }">1</a>
      </div>
    </div>
  </div>

  <div class="wrapper validation">
    <div class="col-12 col-md-6 validation-box">
      <div class="title">Bestätigen Sie Ihre Buchung</div>
      <div class="text-margin" *ngIf="selectedValues">
        Der PIN-Code wurde an Ihr Mobiltelefon mit der Nummer
        <strong>{{ selectedValues.Event.PhoneMobile }}</strong>
        geschickt. Bitte geben Sie den PIN-Code ein oder gehen Sie zurück und korrigieren Sie Ihre Telefonnummer
      </div>
      <form #pinValidationForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="validation-input" *ngIf="verification">
          <label for="title">PIN-Code</label>
          <input
            placeholder="Bitte geben Sie den Bestätigungscode ein"
            type="text"
            pattern="[0-9]{6}"
            minlength="6"
            maxlength="6"
            class="form-control"
            id="title"
            [(ngModel)]="verification.pin"
            name="title"
            autocomplete="one-time-code"
          />
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="verification.pin?.length != 6"
            *ngIf="!ongoingRequest"
            [ngStyle]="{ 'background-color': color }"
          >
            Bestätigen
          </button>
          <button
            class="btn btn-primary"
            type="submit"
            disabled
            *ngIf="!!ongoingRequest"
            [ngStyle]="{ 'background-color': color }"
          >
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>
          <p class="err-msg" *ngIf="!pinChecker">Der eingegebene PIN ist nicht korrekt.</p>
        </div>
      </form>
    </div>
  </div>
</div>
