import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoCallSocketService {
  private localStream$$ = new BehaviorSubject<MediaStream>(null);
  localStream$ = this.localStream$$.asObservable();
  private remoteStream$$ = new BehaviorSubject<MediaStream>(null);
  remoteStream$ = this.remoteStream$$.asObservable();
  private isChannelReady = false;
  private isInitiator = false;

  private socket;
  private pc: RTCPeerConnection;
  private isStarted = false;
  private pcConfig: RTCConfiguration;

  private chatMessages$$ = new BehaviorSubject<any[]>([]);
  chatMessages$ = this.chatMessages$$.asObservable();
  constructor() {}

  async initSocket(token: string, pcConfig: RTCConfiguration, localStream: MediaStream) {
    // this.drawerEl.open();

    this.pcConfig = pcConfig;
    this.localStream$$.next(localStream);
    this.socket = io(environment.patientConnectUrl, {
      path: '/call',
      query: { token },
    }); // .connect();

    this.sendMessage('got user media');
    if (this.isInitiator) {
      this.maybeStart();
    }

    this.socket.on('created', (room) => {
      this.log('Created room ' + room);
      this.isInitiator = true;
    });

    this.socket.on('full', (room) => {
      this.log('Room ' + room + ' is full');
    });

    this.socket.on('join', (room) => {
      this.log('Another peer made a request to join room ' + room);
      this.log('This peer is the initiator of room ' + room + '!');
      this.isChannelReady = true;
    });

    this.socket.on('joined', (room) => {
      this.log('joined: ' + room);
      this.isChannelReady = true;
    });

    this.socket.on('log', (array) => {
      this.log.apply(console, array);
    });

    this.socket.on('disconnect', (reason) => {
      this.log(`Disconnected: ${reason}.`);
      if (reason === 'transport close') {
        this.socket.close();
      }
      // sendBtn.disabled = true;
      // snapAndSendBtn.disabled = true;
    });

    this.socket.on('error', this.log);

    this.socket.on('bye', (room) => {
      this.log(`Peer leaving room ${room}.`);
      // sendBtn.disabled = true;
      // snapAndSendBtn.disabled = true;
      // If peer did not create the room, re-enter to be creator.
      this.remoteStream$$.next(null);
      // remoteVideo.srcObject = null;
      this.isStarted = false;
      this.isInitiator = true;
      // if (!this.isInitiator) {
      //   window.location.reload();
      // }
    });

    ////////////////////////////////////////////////

    // This client receives a message
    this.socket.on('message', (message) => {
      this.log('Client received message:', message);
      if (message === 'got user media') {
        this.maybeStart();
      } else if (message.type === 'offer') {
        if (!this.isInitiator && !this.isStarted) {
          this.maybeStart();
        }
        this.pc.setRemoteDescription(new RTCSessionDescription(message));
        this.doAnswer();
      } else if (message.type === 'answer' && this.isStarted) {
        this.pc.setRemoteDescription(new RTCSessionDescription(message));
      } else if (message.type === 'candidate' && this.isStarted) {
        const candidate = new RTCIceCandidate({
          sdpMLineIndex: message.label,
          candidate: message.candidate,
        });
        this.pc.addIceCandidate(candidate);
      } else if (message.type === 'chat') {
        const chatMessages = [...this.chatMessages$$.getValue(), message];
        this.chatMessages$$.next(chatMessages);
      } else if (message === 'bye' && this.isStarted) {
        this.handleRemoteHangup();
      }
    });
  }

  private maybeStart() {
    this.log('>>>>>>> maybeStart() ', this.isStarted, this.localStream$$.getValue(), this.isChannelReady);
    // this.log({ isStarted, this.localStream, isChannelReady });
    if (!this.isStarted && typeof this.localStream$$.getValue() !== 'undefined' && this.isChannelReady) {
      this.log('>>>>>> creating peer connection');
      this.createPeerConnection();
      this.localStream$$
        .getValue()
        .getTracks()
        .forEach((track) => this.pc.addTrack(track, this.localStream$$.getValue()));
      this.isStarted = true;
      this.log('isInitiator', this.isInitiator);
      if (this.isInitiator) {
        this.doCall();
      }
    }
  }

  private createPeerConnection() {
    try {
      this.pc = new RTCPeerConnection(this.pcConfig);
      this.log(this.pc);
      this.pc.onicecandidate = (event) => this.handleIceCandidate(event);
      this.pc.ontrack = (event) => this.handleRemoteStreamAdded(event);
      this.pc.removeTrack = (event) => this.handleRemoteStreamRemoved(event);
      this.log('Created RTCPeerConnnection');
    } catch (e) {
      this.log('Failed to create PeerConnection, exception: ' + e.message);
      alert('Cannot create RTCPeerConnection object.');
      return;
    }
  }

  private handleIceCandidate(event) {
    this.log('icecandidate event: ', event);
    if (event.candidate) {
      this.sendMessage({
        type: 'candidate',
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate,
      });
    } else {
      this.log('End of candidates.');
    }
  }

  private handleCreateOfferError(event) {
    this.log('createOffer() error: ', event);
  }

  private doCall() {
    this.log('Sending offer to peer');
    this.pc
      .createOffer()
      .then((sessionDescription) => this.setLocalAndSendMessage(sessionDescription))
      .catch(this.handleCreateOfferError);
  }

  private doAnswer() {
    this.log('Sending answer to peer.');
    this.pc
      .createAnswer()
      .then((sessionDescription) => this.setLocalAndSendMessage(sessionDescription))
      .catch((error) => this.onCreateSessionDescriptionError(error));
  }

  private setLocalAndSendMessage(sessionDescription) {
    this.pc.setLocalDescription(sessionDescription);
    this.log('setLocalAndSendMessage sending message', sessionDescription);
    this.sendMessage(sessionDescription);
  }

  private onCreateSessionDescriptionError(error) {
    this.trace('Failed to create session description: ' + error.toString());
  }

  private handleRemoteStreamAdded(event) {
    // remoteStream = event.stream;
    // remoteVideo.srcObject = remoteStream;
    if (this.remoteStream$$.getValue() !== event.streams[0]) {
      this.remoteStream$$.next(event.streams[0]);
      // remoteVideo.srcObject = this.remoteStream;
      this.log('Remote stream added.');
    }
  }

  private handleRemoteStreamRemoved(event) {
    this.log('Remote stream removed. Event: ', event);
  }

  closeConnection() {
    this.log('Hanging up.');
    this.stop();
    // sendMessage('bye');
    this.socket.emit('bye');
  }

  private handleRemoteHangup() {
    this.log('Session terminated.');
    this.stop();
    this.isInitiator = true;
  }

  private stop() {
    this.isStarted = false;
    this.pc.close();
    this.pc = null;
    this.remoteStream$$.next(null);
  }

  // Logs an action (text) and the time when it happened on the console.
  private trace(text) {
    text = text.trim();
    const now = (window.performance.now() / 1000).toFixed(3);

    this.log(now, text);
  }

  private sendMessage(message) {
    this.log('Client sending message: ', message);
    this.socket.emit('message', message);
  }

  private log(...args) {
    if (!environment.production) {
      console.log(...args);
    }
  }

  setMicEnabled(enabled: boolean) {
    this.localStream$$
      .getValue()
      .getAudioTracks()
      .forEach((track) => (track.enabled = enabled));
  }

  setVideoEnabled(enabled: boolean) {
    this.localStream$$
      .getValue()
      .getVideoTracks()
      .forEach((track) => (track.enabled = enabled));
  }

  sendChatMessage(message: string) {
    const chatMessages = [...this.chatMessages$$.getValue(), { message, sender: '_self' }];
    this.chatMessages$$.next(chatMessages);
    this.socket.emit('message', { type: 'chat', message });
  }

  // closeConnection() {
  //   this.socket.disconnect();
  //   // this.socket = null;
  //   this.pc.close();
  //   this.pc = null;
  //   // this.socket.emit('bye');
  // }
}
