<div class="container">
  <div class="header">
    <i class="fa fa-angle-left head" style="color:#2bbfd9"></i>
  </div>

  <div class="wrapper">
    <div class="row extra">

      <div class="col-12">
        <p class="title">
          Nutzungsbedingungen der Online-Terminreservierung
        </p>
        <p class="title2">
          1. Geltung der Nutzungsbedingungen
        </p>
        <p class="text">
          1.1 Diese Nutzungsbedingungen regeln die Nutzung der Online-Terminreservierung (in weiterer Folge auch OTR
          genannt) durch den Patienten und die sich daraus ergebenden Rechtsverhältnisse zum Gesundheitsdienstleister
          (Arzt oder Gruppenpraxis), mit dem der Patient die Terminvereinbarung vornimmt, und zur Peacequare GmbH & Co
          KG. Jede Nutzung der OTR unterliegt diesen Nutzungsbedingungen. Der Patient unterwirft sich den
          Nutzungsbedingungen durch Betätigung der Schaltfläche „Termin buchen“ in der OTR. Von diesen
          Nutzungsbedingungen abweichende Regelungen erlangen keine Geltung, sofern diese nicht ausdrücklich
          schriftlich vereinbart werden.
        </p>
        <p class="text">
          1.2 Die Nutzungsbedingungen gelten für die Rechtsbeziehung zwischen den Parteien im Zusammenhang mit der
          Terminvereinbarung, anlässlich derer der Patient den Nutzungsbedingungen zustimmt. Bei jeder neuerlichen
          Nutzung der OTR unterwirft sich der Patient neuerlich den Nutzungsbedingungen für die jeweilige
          Terminvereinbarung. Die Unterwerfung des Patienten unter diese Nutzungsbedingungen lässt die Geltung der
          Allgemeinen Geschäftsbedingungen der Peacequare GmbH im Zusammenhang mit der Benutzung der
          Gesundheitsplattform termine.meineakte.at (in der Folge die „MeineAkte-Termine-Plattform“) und allfälliger
          allgemeiner Geschäftsbedingungen des Gesundheitsdienstleisters unberührt.
        </p>
        <p class="text">
          1.3 Der Gesundheitsdienstleister bietet dem Patienten die Möglichkeit, über die OTR einen Termin zu
          vereinbaren. Die OTR wurde von der Peacequare GmbH entwickelt und wird dem Gesundheitsdienstleister
          für diesen Zweck zur Verfügung gestellt.
        </p>
        <p class="text">
          1.4 Die OTR ist über die MeineAkte-Termine-Plattform, sowie allenfalls über die Website des
          Gesundheitsdienstleisters zugänglich.
        </p>
        <p class="title2">
          2. Zweck und Funktionalität
        </p>
        <p class="text">
          2.1 Der Gesundheitsdienstleister stellt dem Patienten einen unentgeltlichen Zugang zur Terminreservierung zum
          Zwecke der Vereinbarung von Terminen zur Verfügung.
        </p>
        <p class="text">
          2.2 Patienten haben die Möglichkeit, von Gesundheitsdienstleistern als verfügbar ausgewiesene Termine über
          die Terminreservierung anzufragen und durch Bestätigung durch den Gesundheitsdienstleister zu vereinbaren.
        </p>
        <p class="text">
          2.3 Die Terminreservierung darf nicht zur Terminvereinbarung bei Notfällen oder ähnlichen dringenden
          medizinischen Fällen verwendet werden. Für derartige Fälle wird die direkte Kontaktaufnahme mit dem
          Gesundheitsdienstleister oder einem medizinischen Notfalldienst empfohlen.
        </p>
        <p class="title2">
          3. Terminvereinbarung
        </p>
        <p class="text">
          3.1 Die Vereinbarung von Terminen für Leistungen von Gesundheitsdienstleistern über die Terminreservierung
          ist eine registrierungsfreie Funktion, die keine Einrichtung eines Accounts erforderlich macht. Zur
          Terminanfrage werden jeweils alle dafür notwendigen Angaben abgefragt. Der Patient versichert, dass alle von
          ihm im Rahmen des Registrierungsprozesses angegebenen Daten zutreffend, vollständig und wahrheitsgemäß sind.
          Für die von ihm angegebenen Daten ist ausschließlich der Patient selbst verantwortlich. Die Peacequare GmbH &
          Co KG nimmt keinen Einfluss auf diese Daten und ihre Inhalte und haftet insbesondere nicht für die
          Richtigkeit der Inhalte oder sich aus diesen ergebenden Schäden.
        </p>
        <p class="text">
          3.2 Zur Vereinbarung von Terminen mit Gesundheitsdienstleistern über die Terminreservierung wählt der Patient
          die jeweilige Leistung sowie einen bestimmten Termin beim Gesundheitsdienstleister aus. Sofern vom
          Gesundheitsdienstleister für die jeweilige Leistung vorgesehen, kann der Patient außerdem einen bestimmten
          Mitarbeiter des Gesundheitsdienstleisters für die Leistungserbringung auswählen. Dessen ungeachtet ist der
          Gesundheitsdienstleister berechtigt, sich beziehungsweise den Mitarbeiter vertreten zu lassen (z.B. im
          Verhinderungsfall).
        </p>
        <p class="text">
          3.3 Durch Bestätigen einer hierfür vorgesehenen Schaltfläche sendet der Patient eine Terminanfrage mit dem
          Inhalt der von ihm eingegebenen Daten an den Gesundheitsdienstleister. Mit Absendung der Terminanfrage bietet
          der Patient dem Gesundheitsdienstleister an, ihn über die von ihm angegebene Email Adresse und/oder
          Telefonnummer zu kontaktieren. Weiters sagt er zu, diese Email Adresse regelmäßig abzufragen. Die
          Terminvereinbarung kommt mit Bestätigung des Termins durch den Gesundheitsdienstleister zustande, die durch
          Übersendung einer entsprechenden E-Mail oder eines Telefonats erfolgt.
        </p>
        <p class="text">
          3.4 Kann der Patient den Termin nicht einhalten, so hat er den Gesundheitsdienstleister darüber unverzüglich,
          spätestens 24 Stunden vor dem Termin, zu informieren.
        </p>
        <p class="text">
          3.5 Der Patient nimmt zur Kenntnis, dass über die OTR keine rechtsverbindlichen Verträge über die
          ausgewählten Leistungen mit Gesundheitsdienstleistern abgeschlossen werden können und die Buchung eines
          Termins lediglich eine Terminvereinbarung und daher keinen Behandlungsvertrag darstellt. Diese begründet
          daher keine Leistungspflichten des Gesundheitsdienstleisters. Der Abschluss eines Behandlungsvertrags erfolgt
          nicht über die Terminreservierung, sondern im direkten Kontakt zwischen Patienten und
          Gesundheitsdienstleister.
        </p>
        <p class="text">
          3.6 Der Patient nimmt zur Kenntnis, dass es trotz Terminvereinbarung zu Wartezeiten kommen kann, da die Dauer
          der Behandlung der vorhergehenden Patienten im Vorhinein nicht genau abschätzbar ist.
        </p>
        <p class="title2">
          4. Nutzung der Online-Terminreservierung, Umfang der Nutzungsberechtigung
        </p>
        <p class="text">
          4.1 Die Berechtigung des Patienten beschränkt sich auf den Zugang zu der OTR, sowie auf die Nutzung der in
          dieser OTR jeweils verfügbaren Funktionalitäten und Inhalte im Rahmen der Regelungen dieser
          Nutzungsbedingungen. Die Nutzung ist ausschließlich für eigene private Zwecke erlaubt. Die Nutzung zur
          Vereinbarung von Terminen für Dritte ist nicht zulässig.
        </p>
        <p class="text">
          4.2 Soweit nicht in diesen Nutzungsbedingungen oder in der OTR eine weitergehende Nutzung ausdrücklich
          erlaubt oder in der OTR durch eine entsprechende Funktionalität ermöglicht wird, darf der Patient die in der
          OTR verfügbaren Inhalte ausschließlich zum Zweck der Vereinbarung eines eigenen Termins und die Dauer der
          Nutzung der OTR abrufen und anzeigen. Der Patient ist nicht berechtigt, die in der OTR verfügbaren Inhalte
          ganz oder teilweise zu bearbeiten, zu verändern, zu übersetzen, vorzuführen, zu veröffentlichen,
          auszustellen, zu vervielfältigen oder zu verbreiten. Der Patient darf insbesondere etwaige Urhebervermerke,
          Markenzeichen, Logos und/oder sonstige Kennzeichen oder Schutzvermerke nicht von Inhalten der OTR entfernen
          oder diese verändern.
        </p>
        <p class="text">
          4.3 Der Patient verpflichtet sich insbesondere die OTR nicht in einer Weise zu nutzen, die Rechte Dritter
          verletzt (insbesondere das Recht auf Privatsphäre oder das Persönlichkeitsrecht), die OTR nicht zu
          kommerziellen Zwecken zu nutzen, über die OTR keine Inhalte verfügbar oder sonst zugänglich zu machen, die
          gegen gesetzliche Vorschriften verstoßen
        </p>
        <p class="text">
          4.4 Der Gesundheitsdienstleister ist nicht verpflichtet, dem Patienten die Nutzung der OTR zur Verfügung zu
          stellen, ihn zu dieser zuzulassen oder darüber mit ihm zu kommunizieren. Der Gesundheitsdienstleister kann
          vielmehr dem Patienten die Kommunikation mit ihm über die OTR untersagen und den Patienten sperren,
          insbesondere wenn dieser gegen die Nutzungsbedingungen oder die einschlägigen gesetzlichen Bestimmungen
          verstößt.
        </p>
        <p class="title2">
          5. Verfügbarkeit
        </p>
        <p class="text">
          Durch die Nutzung der OTR erlangt der Patient keinen Anspruch auf die Nutzung derselben. Die Möglichkeit der
          Nutzung wird dem Patienten unentgeltlich eingeräumt und besteht ausschließlich im Umfang der jeweiligen
          Verfügbarkeit der OTR. Es wird keine bestimmte Verfügbarkeit oder Funktionalität zugesagt. Die OTR kann
          jederzeit in ihrer Funktionalität erweitert oder eingeschränkt oder auch gänzlich beendet werden. Zwischen
          Patienten und Gesundheitsdienstleister bereits getroffene Terminvereinbarungen bleiben von einer allfälligen
          Änderung oder Einstellung der OTR unberührt.
        </p>
        <p class="title2">
          6. Datenschutz
        </p>
        <p class="text">
          Sowohl dem Gesundheitsdienstleister, als auch der Peacequare GmbH ist Datenschutz ein wichtiges
          Anliegen. Die Verwendung der personenbezogenen Daten des Patienten erfolgt ausschließlich unter Beachtung der
          einschlägigen datenschutzrechtlichen Gesetze und Verordnungen und nach Maßgabe der „Datenschutzerklärung der
          Online-Terminvereinbarung“.
        </p>
        <p class="title2">
          7. Zurverfügungstellung der Online-Terminvereinbarung durch die Peacequare GmbH als
          Auftragsverarbeiter
        </p>
        <p class="text">
          7.1 Die Peacequare GmbH stellt dem Gesundheitsdienstleister in dessen Auftrag die OTR als technische
          Infrastruktur zur Kommunikation mit dem Patienten zur Verfügung. Dem Patienten obliegt die
          eigenverantwortliche Nutzung zum Zwecke der Terminvereinbarung mit dem Gesundheitsdienstleister. Die
          Peacequare GmbH hat dabei selbst keinen Einfluss auf die konkrete Nutzung der OTR durch den
          Gesundheitsdienstleister oder den Patienten, insbesondere nicht auf die vom Gesundheitsdienstleister über die
          OTR als verfügbar ausgewiesenen Termine oder dazugehörige -von Gesundheitsdienstleistern gestaltete Inhalte-
          wie zum Beispiel der Leistungsbenennung.
        </p>
        <p class="text">
          7.2 Über die OTR kommen ausschließlich Terminvereinbarungen, jedoch keine rechtsverbindlichen Verträge
          zwischen Gesundheitsdienstleistern und Patienten wie etwa Behandlungsverträge zustande. Die
          Terminvereinbarung begründet keine Verpflichtung des Patienten oder Gesundheitsdienstleisters zur Eingehung
          eines auf die jeweilige Leistung gerichteten Vertrages. Ein etwaiger Vertragsschluss zwischen Patienten und
          Gesundheitsdienstleisters erfolgt ausschließlich im direkten persönlichen Kontakt zwischen Patienten und
          Gesundheitsdienstleister.
        </p>
        <p class="text">
          7.3 Vertragspartner eines etwaigen Vertrags über die von Gesundheitsdienstleistern angebotenen Leistungen
          sind ausschließlich der Patient und der jeweilige Gesundheitsdienstleister. Die Peacequare GmbH ist
          nicht Partei des Vertrages und hat auch keinen Einfluss auf die Ausgestaltung des rechtlichen Verhältnisses
          zwischen Gesundheitsdienstleister und Patient.
        </p>
        <p class="text">
          7.4 Die Peacequare GmbH hat auch keinen Einfluss auf die Erbringung der von Gesundheitsdienstleistern
          angebotenen Leistungen oder deren Qualität oder auf die Inanspruchnahme der Leistungen durch den Patienten.
          Die Peacequare GmbH übernimmt insbesondere keine Verantwortung dafür, dass die von Patienten
          angefragten oder vereinbarten Termine mit Gesundheitsdienstleistern tatsächlich verfügbar sind oder dass die
          von Patienten gewünschten Leistungen von Gesundheitsdienstleistern tatsächlich erbracht werden, der zwischen
          Patient und Gesundheitsdienstleistern vereinbarten Qualität und/oder sonstigen etwaigen vertraglichen
          Vereinbarungen entsprechen und/oder sonst mangelfrei sind. Die Peacequare GmbH trifft außerdem keine
          Verantwortlichkeit für etwaige Schäden, die dem Patienten im Zusammenhang mit der Leistungserbringung durch
          den Gesundheitsdienstleister entstehen.
        </p>
        <p class="title2">
          8. Haftung
        </p>
        <p class="text">
          Für etwaige Schäden, die durch die Nutzung der unentgeltlichen OTR entstehen, haftet der
          Gesundheitsdienstleister nur bei Vorsatz oder grober Fahrlässigkeit und nur soweit, als der Schaden aufgrund
          der vertragsgemäßen Nutzung der OTR entstanden ist. Eine Haftung der Peacequare GmbH gegenüber dem
          Patienten ist ausgeschlossen.
        </p>
        <p class="title2">
          9. Sonstiges
        </p>
        <p class="text">
          9.1 Die Rechtsverhältnisse des Patienten zum Gesundheitsdienstleister und zur Peacequare GmbH
          unterliegen ausschließlich österreichischem Recht.
        </p>
        <p class="text">
          9.2 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen zur Gänze oder teilweise unwirksam oder
          undurchsetzbar sein oder werden, so berührt dies nicht die Wirksamkeit der übrigen Bestimmungen.
        </p>
      </div>
    </div>
  </div>
</div>
