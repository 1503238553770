import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PracticeInfo } from '../PracticeInfo';
import { SelectedValues } from '../SelectedValues';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.css'],
})
export class Step5Component implements OnInit {
  @Input() selectedValues: SelectedValues;
  @Input() practiceInfos: PracticeInfo;
  @Input() selectedRoom: any;
  @Input() color;
  @Input() isVAMED?: boolean;
  @Input() physicianPicture?: string;
  @Input() personPicture?: string;

  @Output() nextStep = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  goTo(step: number): void {
    // console.log(this.selectedValues);
    // console.log(this.practiceInfos);
    this.nextStep.emit(step);
  }
}
