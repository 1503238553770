import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SelectedValues } from '../SelectedValues';
import { ValiInfo } from '../ValiInfo';

export interface IPracticeDataResponse {
  practice: IPractice;
  eventtypes: ICCTaskEventType[];
  rooms: ICCRoom[];
  owner?: CCTaskOwner;
  eventTypeGroup?: ICCTaskEventTypeGroup;
  /** @description represents the selected eventType EventName */
  event?: string;
}

export interface IPractice {
  id: string;
  Name: string;
  Name2: string;
  PatientNotes: string;
  PhysicianPicture: string;
  PortalColor: string;
  EnableSameDayBooking?: boolean;
  EnableGenderValidation?: boolean;
  EnableTaskNotesFormField?: boolean;
  CustomTermsURL?: string;
}

export interface ICCTaskEventType {
  id: number;
  EventName: string;
  EventDetails: string;
  RoomIDs: string;
  Care01EventTypeID: number;
  OwnerIDs: string;
}

export interface ICCRoom {
  id: number;
  RoomName: string;
  LocationDescription: string;
  Care01RoomID: number;
}

export interface CCTaskOwner {
  id: number;
  Care01OwnerID: number;
  PersonName: string;
  PersonDescription: string;
  PersonPicture: string;
}

export interface ICCTaskEventTypeGroup {
  id: number;
  GroupName: string;
  GroupPicture?: string;
  EventTypeIDs?: [];
  EventTypeOwnerIDs?: [];
  GroupDescription: string;
}

export interface RootObject {}

@Injectable()
export class DatesService {
  private url = environment.meineAkteUrl;

  constructor(private http: HttpClient) {}

  getDates(
    portalID: string,
    eventTypeID: string,
    fromDate: Date = new Date(),
    toDate = null,
    roomID = null,
    ownerID = null,
    groupID = null
  ): Promise<any> {
    const queryValues = {
        pid: portalID,
        tid: eventTypeID,
        rid: roomID,
        from: fromDate,
        to: toDate,
        ownerID,
        gid: groupID
      },
      queryValuesB64 = btoa(JSON.stringify(queryValues));
    const params = new HttpParams().set('q', queryValuesB64);
    return this.http.get(this.url + 'api/appointments', { params: params }).toPromise();
  }

  getPracticeData(portalID: string, ownerID?: number, groupID?: string): Promise<IPracticeDataResponse> {
    const queryValues = { pid: portalID, ownerID, groupID },
      queryValuesB64 = btoa(JSON.stringify(queryValues));
    // const params = new HttpParams().set('q', 'eyJwaWQiOiI4NTQxNjhGQi00RkE1LUU4MTEtQkNFNy0wMDAzRkY1MzA0ODEifQ==');
    const params = new HttpParams().set('q', queryValuesB64);
    return this.http.get<IPracticeDataResponse>(this.url + 'api/practice', { params: params }).toPromise();
  }

  insert(data: SelectedValues): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.url + 'api/appointments', data, httpOptions).toPromise();
  }

  verification(data: ValiInfo): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.url + 'api/validatepin', data, httpOptions).toPromise();
  }

  /**
   * @description used for validation of the given slot, whether it's still available or already blocked / booked
   * @param body
   * @returns
   */
  validateSlot(body: {
    start: string;
    end: string;
    roomID?: number;
    ownerID?: number;
    portalID: string;
    eventTypeID: number;
    check: string;
  }): Promise<{ valid: boolean }> {
    return this.http.post<{ valid: boolean }>(this.url + 'api/validateslot', body).toPromise();
  }
}
