<div class="container">
  <div class="header" [ngStyle]="{ 'background-color': color }">
    <div class="d-flex justify-content-between">
      <div>
        <a><i class="fa fa-angle-left head mouseover" (click)="backToStep1()"></i></a>
      </div>
      <div class="mt-1" *ngIf="isVAMED">
        <a href="https://www.teamgesund.at/" target="_blank">
          <img src="../../../assets/images/logo_tgm_60h.png" alt="logo" class="img-logo" />
        </a>
      </div>
      <div>
        <a class="circle not-selected last">4</a>
        <a class="circle not-selected">3</a>
        <a class="circle selected mouseover" [ngStyle]="{ color: color }">2</a>
        <a class="circle selected mouseover" (click)="goTo(1)" [ngStyle]="{ color: color }">1</a>
      </div>
    </div>
  </div>
  <div class="wrapper time">
    <div class="row time">
      <div class="col-12 col-md-6 col-time" *ngIf="practiceInfos">
        <!-- <input class="date-event title" [(ngModel)]="this.practiceInfos.event" [readonly]="true">
        <input class="date-event title" [value]="currentRoomName(selectedValues.RoomID)" [readonly]="true" *ngIf="selectedValues.RoomID"> -->
        <span class="date-event title">{{ practiceInfos.event }}</span>
        <span class="date-event title" *ngIf="selectedRoom">-</span>
        <span class="date-event title" *ngIf="selectedRoom">{{ selectedRoom.RoomName }}</span>
      </div>

      <div class="col-6 col-md-3 col-time">
        <div class="input-group">
          <input
            class="form-control custom"
            (click)="d.toggle()"
            name="dp"
            (ngModelChange)="onDatePick($event)"
            [(ngModel)]="datepickerPlaceholder"
            [minDate]="minDate"
            [maxDate]="maxDate"
            ngbDatepicker
            #d="ngbDatepicker"
            id="inputGroupSelect01"
          />
          <div class="input-group-append">
            <button
              class="button-custom calendar"
              (mouseover)="hoverDate = true"
              (mouseleave)="hoverDate = false"
              (click)="d.toggle()"
              type="button"
              [ngStyle]="{
                color: hoverDate == true ? 'white' : color,
                'border-color': color,
                'background-color': hoverDate == true ? color : 'white'
              }"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-time">
        <div ngbDropdown class="date">
          <button class="btn btn-outline-primary no-border" id="dropdownBasic1" ngbDropdownToggle>
            {{ selectedTime.time }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" *ngFor="let times of customTimes" (click)="onTimePick(times)">
              {{ times.time }}
            </button>
          </div>
        </div>
        <!-- <select class="date" type="number" [(ngModel)]="selectedTime" (ngModelChange)="onTimePick(selectedTime.value)">
          <option *ngFor="let times of customTimes" [ngValue]="times">{{times.time}}</option>
        </select> -->
        <!-- <ngb-timepicker [(ngModel)]="time" (ngModelChange)="onTimePick($event)" [spinners]="false"></ngb-timepicker> -->
      </div>
    </div>
  </div>

  <div class="wrapper">
    <div *ngFor="let day of filteredDates">
      <div *ngIf="day.slots.length > 0">
        <div class="row dates" *ngIf="day.slots.length > 0">
          <div class="col-12 col-sm-3 col-lg-2 col-md-3 day">
            <div class="box" [ngStyle]="{ background: color, 'border-color': color }">
              {{ day.slots[0].Start | dayjsDate : 'EEE dd.LL.yyyy' }}
            </div>
          </div>
          <div class="col no-padding">
            <div class="row no-pad-border">
              <div
                class="col-lg-1 col-md-2 col-sm-2 col-4 time"
                *ngFor="let slot of day.slots"
                (click)="onSelect(slot.Start, slot.End, slot.RoomID, slot.OwnerID)"
              >
                <button
                  type="button"
                  class="button-custom"
                  (mouseover)="slotHovered(slot, true)"
                  (mouseleave)="slotHovered(slot, false)"
                  [ngStyle]="{
                    color: slot.isHovered == true ? 'white' : color,
                    'border-color': color,
                    'background-color': slot.isHovered == true ? color : 'white'
                  }"
                >
                  {{ slot.Start | dayjsDate : 'HH:mm' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Err Msg wenn keine es keine verfügbaren Termine an dem gewählten Tag gibt. -->
    <div class="row dates" [hidden]="dates.length != 0">
      Es wurden keine freien Termine bis zum {{ currentToDate() | date }} gefunden. Bitte Wählen Sie ein sp&auml;teres
      Datum.
      <!-- Es wurden keine Termine an dem Tag gefunden. Bitte Wählen Sie einen anderen Tag. -->
    </div>
  </div>
  <div class="wrapper">
    <button
      type="button"
      class="button-custom"
      *ngIf="showNext"
      (mouseover)="hoverMore = true"
      (mouseleave)="hoverMore = false"
      (click)="loadNext()"
      [ngStyle]="{
        color: hoverMore == true ? 'white' : color,
        'border-color': color,
        'background-color': hoverMore == true ? color : 'white'
      }"
    >
      Mehr anzeigen
    </button>
  </div>
</div>
