import { Event } from './Event';

export class SelectedValues {
  PortalID: string;
  EventTypeID: string;
  OwnerID?: number;
  Event: Partial<Event>;
  RoomID: number;
  Description?: string;

  constructor() {
    this.PortalID = '';
    this.EventTypeID = '';
    this.OwnerID = null;
    this.Event = new Event();
    this.RoomID = null;
    this.Description = '';
  }
}
