<div class="call-component">
  <ng-container *ngIf="authRequest$ | async as authResult; else loadingOrError">
    <mat-drawer-container class="h-100" [hasBackdrop]="'false'">
      <mat-drawer #drawer [mode]="'side'" class="rounded" position="end">
        <i class="fas fa-angle-right float-left m-2" (click)="drawer.close()" title="Chat schlie&szlig;en"></i>
        <app-chat [chatMessages]="chatMessages$ | async" (sendChatMessage)="sendChatMessage($event)"></app-chat>
      </mat-drawer>
      <mat-drawer-content>
        <div class="container-fluid d-flex h-100 flex-column">
          <app-video-call-menu
            [hasPeerConnection]="hasPeerConnection$$ | async"
            [drawerEl]="drawer"
            [timer]="timer$ | async"
            (closePeerConnection)="closeConnection()"
            (openConnection)="initializeCall()"
            (toggleMic)="toggleMic($event)"
            (toggleVideo)="toggleVideo($event)"
          ></app-video-call-menu>
          <div class="row flex-grow-1 h-100">
            <app-video-call
              class="col-12 d-flex"
              [localStream]="localStream$ | async"
              [remoteStream]="remoteStream$ | async"
            ></app-video-call>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-container>
</div>

<ng-template #loadingOrError>
  <div class="container d-flex">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <ng-container *ngIf="errorObject; else loading">
          <div class="card">
            <div class="card-body">
              <div class="card-text pre-wrap">
                {{
                  errorObject?.type == 'INIT_ERROR' && errorObject?.message
                    ? errorObject.message.trim()
                    : 'Ungültige Meeting ID oder falsches Passwort!'
                }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <div class="spinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-template>
</ng-template>

<!-- <div class="container">
    <div class="row">
      <div class="col col-12">no token</div>
    </div>
  </div>
</ng-template> -->
