import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { find } from 'lodash';
import * as moment from 'moment';
import { SelectedValues } from './SelectedValues';
import { DatesService, ICCTaskEventTypeGroup, IPracticeDataResponse } from './services/dates.service';
import { RoomsService } from './services/rooms.service';
import { ValiInfo } from './ValiInfo';
import { PracticeInfo } from './PracticeInfo';
import { Observable, ObservedValueOf } from 'rxjs';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css']
})
export class BookAppointmentComponent implements OnInit {
  public step: number;
  public portalID: string;
  public ownerID: number;
  public groupID: string;
  public slotValue: string;
  public selectedValues = new SelectedValues();
  public practiceInfos: IPracticeDataResponse;
  public appointmentInfo: { service: string; practice: string };
  public services;
  public dates;
  public rooms;
  public selectedRoomID;
  public filteredDates;
  public verification = new ValiInfo();
  public mobileErr = false;
  public appointmentTakenErr = false;
  public lastSearch: Date;
  public limit: Date;

  public colorIsLoaded = false;
  public color;
  public submitPending = false;

  public physicianPicture: SafeResourceUrl;
  public personPicture: SafeResourceUrl;

  public isVAMED: boolean;

  public errorMessage: string;

  public currentRooms$: Observable<any>;

  constructor(
    private datesService: DatesService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private roomsService: RoomsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit() {
    this.isVAMED = this.document?.location?.hostname?.indexOf('.vamed.com') > -1;
    const { queryParamMap, paramMap } = this.route.snapshot;
    this.currentRooms$ = this.roomsService.items$;
    // get portalID from route
    this.portalID = paramMap.get('id');

    // get values from optional query parameters
    this.ownerID = queryParamMap.get('Owner') ? parseInt(queryParamMap.get('Owner')) : null;
    this.groupID = queryParamMap.get('groupID');

    const practiceData = await this.fetchPracticeData(this.portalID, this.ownerID, this.groupID);

    // get parameters for specific timeslot & eventType from query parameters
    const start = queryParamMap.get('start');
    const end = queryParamMap.get('end');
    const eventTypeID = queryParamMap.get('event');
    const roomID = queryParamMap.get('room');
    const check = queryParamMap.get('check');
    // the employee param is used for the ownerID / employeeID of the task
    // separate parameter as the "Owner" has different effects and is used for clinic environments
    const employeeID = queryParamMap.get('employee');
    if (start && end && eventTypeID) {
      try {
        await this.processSlotFromQuery({
          start,
          end,
          eventTypeID,
          roomID,
          practiceData,
          check,
          employeeID
        });
        this.fetchDates();
        this.step = 3;
      } catch (err) {
        console.log(err);
        this.errorMessage = 'Ungültige Termindaten';
      }
    } else {
      this.step = 1;
    }
  }

  setValues(values: SelectedValues) {
    this.selectedValues = values;
    // console.log(this.selectedValues);
  }

  setInfos(infos: IPracticeDataResponse) {
    this.practiceInfos = infos;
    // console.log(this.practiceInfos);
  }

  async processSlotFromQuery({
    start,
    end,
    eventTypeID,
    roomID,
    practiceData,
    check,
    employeeID
  }: {
    start: string;
    end: string;
    eventTypeID: string;
    roomID?: string;
    practiceData: IPracticeDataResponse;
    check: string;
    employeeID: string;
  }) {
    this.errorMessage = '';
    const eventType = practiceData?.eventtypes?.find(
      (evtType) => evtType.Care01EventTypeID == parseInt(eventTypeID, 10)
    );
    if (!eventType) {
      throw new Error('invalid event type');
    }
    const valRes = await this.datesService.validateSlot({
      start,
      end,
      portalID: this.portalID,
      ...(roomID && { roomID: parseInt(roomID, 10) }),
      eventTypeID: parseInt(eventTypeID, 10),
      ownerID: parseInt(employeeID),
      check
    });
    if (!valRes.valid) {
      throw new Error('invalid date params');
    }

    this.setInfos({ ...this.practiceInfos, event: eventType.EventName });
    this.setValues({
      Event: { Start: start, End: end, RoomID: parseInt(roomID, 10), Description: eventType?.EventName },
      EventTypeID: eventTypeID,
      ...(roomID && { RoomID: parseInt(roomID, 10) }),
      PortalID: this.portalID,
      OwnerID: this.ownerID,
      Description: eventType?.EventName
    });
  }

  onClick(nextStep: number) {
    if (nextStep === 2) {
      this.fetchDates();
    } else if (nextStep === 3) {
      this.appointmentTakenErr = false;
    }
    this.step = nextStep;
  }

  getRoom(roomID: number) {
    return find(this.roomsService.get(), function (item) {
      return item.Care01RoomID === roomID;
    });
  }

  async onSubmit(value: any) {
    // console.log(value);
    try {
      this.mobileErr = false;
      this.appointmentTakenErr = false;
      this.submitPending = true;

      const temp = await this.datesService.insert(this.selectedValues);

      this.verification.taskID = temp.id;
      this.step = 4;
    } catch (err) {
      if (err.error && err.error.code === 6) {
        this.mobileErr = true;
      } else if (err.error && err.error.code === 2) {
        this.appointmentTakenErr = true;
      }
      console.log(err);
    } finally {
      this.submitPending = false;
    }
  }

  async fetchPracticeData(portalID: string, ownerID?: number, groupID?: string): Promise<IPracticeDataResponse> {
    if (!portalID) {
      return;
    }
    try {
      const practiceData = await this.datesService.getPracticeData(portalID, ownerID, groupID);
      this.color = practiceData.practice.PortalColor;
      this.services = practiceData.eventtypes;
      this.roomsService.set(practiceData.rooms);
      if (practiceData.rooms.length) {
        this.selectedRoomID = practiceData.rooms[0].Care01RoomID;
      }
      this.selectedValues.PortalID = practiceData.practice.id;
      this.selectedValues.OwnerID = practiceData.owner?.id;

      this.practiceInfos = practiceData;
      if (!practiceData.eventTypeGroup) {
        this.physicianPicture = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.practiceInfos.practice.PhysicianPicture
        );
      } else {
        // use group picture if a group entry available
        this.physicianPicture = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.practiceInfos.eventTypeGroup.GroupPicture
        );
      }

      if (this.practiceInfos.owner) {
        this.personPicture = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.practiceInfos.owner.PersonPicture
        );
      }
      this.colorIsLoaded = true;
      return practiceData;
    } catch (err) {
      console.log(err);
    }
  }

  async fetchDates({ selectedDate, append }: { selectedDate: Date; append?: boolean } = { selectedDate: new Date() }) {
    try {
      if (append) {
        selectedDate = this.lastSearch;
        selectedDate.setHours(2, 0, 0, 0);
        selectedDate = moment(selectedDate).add(1, 'd').toDate();
      } else {
        this.limit = moment(selectedDate).add(1, 'y').toDate();
      }
      const toDate = moment(selectedDate).add(30, 'd').toDate();
      const datesData = await this.datesService.getDates(
        this.selectedValues.PortalID,
        this.selectedValues.EventTypeID,
        selectedDate,
        toDate,
        this.selectedValues.RoomID,
        this.selectedValues.OwnerID,
        this.practiceInfos?.eventTypeGroup?.id
      );

      this.dates = datesData.openHours?.sort(function compare(a, b) {
        if (moment(a.date, 'DD.MM.YYYY').valueOf() < moment(b.date, 'DD.MM.YYYY').valueOf()) {
          return -1;
        }
        if (moment(a.date, 'DD.MM.YYYY').valueOf() > moment(b.date, 'DD.MM.YYYY').valueOf()) {
          return 1;
        }
        return 0;
      });
      this.lastSearch = new Date(datesData.toDate);
      this.filteredDates = append
        ? [...this.filteredDates, ...JSON.parse(JSON.stringify(this.dates))]
        : JSON.parse(JSON.stringify(this.dates));
    } catch (err) {
      console.log(err);
    }
  }

  clearDates() {
    this.filteredDates = [];
  }
}
