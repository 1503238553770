import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css']
})
export class VideoCallComponent implements OnInit {

  @Input()
  localStream: MediaStream;

  @Input()
  remoteStream: MediaStream;

  constructor() { }

  ngOnInit() {
  }

}
