<div class="row justify-content-center">
  <div class="btn-toolbar">
    <div class="btn-group">
      <div class="alert alert-secondary text-white">
        <span *ngIf="timer as timer; else noTimer"
          >{{ timer?.minutes | number: '2.0-0' }}:{{ timer?.seconds | number: '2.0-0' }}</span
        >
        <ng-template #noTimer>
          <span>00:00</span>
        </ng-template>
      </div>
      <button class="btn btn-secondary" (click)="onToggleVideo(videoEnabled)" title="Webcam aktivieren/deaktivieren">
        <mat-icon *ngIf="videoEnabled; else disabledVid">videocam</mat-icon>
        <ng-template #disabledVid>
          <mat-icon>videocam_off</mat-icon>
        </ng-template>
      </button>
      <button class="btn btn-secondary" (click)="onToggleMic(micEnabled)" title="Mikrofon aktivieren/deaktivieren">
        <mat-icon *ngIf="micEnabled; else disabledMic">mic</mat-icon>
        <ng-template #disabledMic>
          <mat-icon>mic_off</mat-icon>
        </ng-template>
      </button>
      <button
        class="btn btn-secondary"
        (click)="drawerEl.opened ? drawerEl.close() : drawerEl.open()"
        title="Chat &ouml;ffnen/schlie&szlig;en"
        [ngClass]="{ 'chat-open': drawerEl.opened }"
      >
        <!-- <i class="fas fa-comments float-right"></i> -->
        <mat-icon>comment</mat-icon>
      </button>
      <!-- *ngIf="hasPeerConnection" -->
      <button class="btn btn-secondary bg-danger" (click)="onCloseConnection()" title="Sitzung verlassen">
        <mat-icon>call_end</mat-icon>
      </button>
      <!-- <button class="btn btn-secondary bg-success" (click)="onCloseConnection()" title="Sitzung verlassen">
        <mat-icon>call</mat-icon>
      </button> -->
    </div>
  </div>
</div>
