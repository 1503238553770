import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  @Input()
  chatMessages: any[];

  @Output()
  sendChatMessage = new EventEmitter<string>();

  currentMessage: string;
  constructor() {}

  ngOnInit() {
    this.currentMessage = '';
  }

  onSendClick(message: string) {
    if (!message) {
      return;
    }
    this.currentMessage = '';
    this.sendChatMessage.next(message);
  }
}
