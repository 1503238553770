import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentRequestResultModel } from './payment-request-result.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { PaymentResultModel } from './payment.result.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private config: ConfigService) {
  }

  requestPayment(): Observable<PaymentRequestResultModel> {
    return this.http.get<PaymentRequestResultModel>(`${this.config.apiPath}payment`);
  }

  add(paymentId): Observable<PaymentResultModel> {
    return this.http.post<PaymentResultModel>(`${this.config.apiPath}payment?id=` + paymentId, {});
  }
}
