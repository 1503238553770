  <div class="embed-responsive embed-responsive-16by9">
    <video
      class="embed-responsive-item rounded-sm shadow-sm"
      [srcObject]="remoteStream"
      #remoteVideo
      autoplay
      playsinline
    ></video>
  </div>

  <div class="position-absolute bottom-right w-20">
    <video
      class="rounded-sm shadow-sm w-100"
      [muted]="true"
      [srcObject]="localStream"
      #video
      autoPlay
      playsInline
    ></video>
  </div>
